// 公共
const tools = {
	create: {
		url: "/addons/chatgpt/image_fix/create",
		method: "POST",
		desc: "发送工具图片"
	}, 
    getTask: {
		url: "/addons/chatgpt/image_fix/getTask",
		method: "POST",
		desc: "获取工具返回结果"
	}, 

}

export default tools
